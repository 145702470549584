import React from 'react';
import { gql } from '@apollo/client';
import { EGDSPrimaryButton, EGDSTertiaryButton, EGDSButtonProps } from '@egds/react-core/button';
import { FragmentComponent } from '../../component-types';
import { EGDSButtonFragment } from 'src/common/__generated__/api/types';

const __egdsButtonFragment = gql`
  fragment EGDSButton on EGDSButton {
    __typename
    primary
    action {
      accessibility
      analytics {
        linkName
        referrerId
      }
      ... on UILinkAction {
        resource {
          __typename
          value
        }
      }
    }
  }
`;

interface InternalEGDSEGDSButtonProps {
  data: EGDSButtonFragment;
  onClick: () => void;
}

export const InternalEGDSEGDSButton: FragmentComponent<InternalEGDSEGDSButtonProps, EGDSButtonFragment> = ({
  data,
  onClick,
  ...otherProps
}: InternalEGDSEGDSButtonProps) => {
  const { primary, __typename } = data;

  const buttonProps: EGDSButtonProps = {
    type: 'button',
    onClick: onClick,
  };

  if (__typename === 'UIPrimaryButton' && data.action.__typename === 'UILinkAction') {
    buttonProps['tag'] = 'a';
    buttonProps['isFullWidth'] = true;
    buttonProps['href'] = data.action.resource.value;
  }

  switch (__typename) {
    case 'UIPrimaryButton':
      return (
        <EGDSPrimaryButton {...buttonProps} {...otherProps}>
          {primary}
        </EGDSPrimaryButton>
      );
    case 'UITertiaryButton':
      return (
        <EGDSTertiaryButton {...buttonProps} {...otherProps}>
          {primary}
        </EGDSTertiaryButton>
      );
  }
};
InternalEGDSEGDSButton.fragment = __egdsButtonFragment;
InternalEGDSEGDSButton.displayName = 'InternalEGDSEGDSButton';
