import React from 'react';
import { gql } from '@apollo/client';
import { EGDSHeading, EGDSText } from '@egds/react-core/text';
import { EGDSLink } from '@egds/react-core/link';
import { EGDSImage, EGDSFigure, EGDSFigureAspectRatioType } from '@egds/react-core/images';
import { EGDSCard } from '@egds/react-core/cards';
import { EGDSSpacing } from '@egds/react-core/spacing';
import { FragmentComponent } from 'components/component-types';
import { EGDSLayoutGrid, EGDSLayoutGridItem } from '@egds/react-core/layout-grid';
import { TaapAcademyComponentFieldsFragment } from 'common/__generated__/api/types';
import { useEgClickstream } from '@shared-ui/clickstream-analytics-context';
import { buildClickStreamAnalyticsForTaapAcademySelectedEvent } from 'common/helper';

const fragment = gql`
  fragment TaapAcademyComponentFields on TaapAcademyComponent {
    heading
    description
    iconUrl
    academyLink {
      text
      action {
        accessibility
        resource {
          value
        }
      }
    }
  }
`;

interface TaapAcademyComponentProps {
  data: TaapAcademyComponentFieldsFragment;
}

export const TaapAcademy: FragmentComponent<TaapAcademyComponentProps, TaapAcademyComponentFieldsFragment> = (
  props: TaapAcademyComponentProps
) => {
  const { data } = props;
  const trackClick = useEgClickstream();
  return (
    <>
      <EGDSCard padded border>
        <EGDSLayoutGrid columns={['12x', '1fr']} space="four" alignItems="center">
          <EGDSLayoutGridItem>
            <div>
              <EGDSFigure ratio={EGDSFigureAspectRatioType.R1_1}>
                <EGDSImage src={data.iconUrl} alt="TAAP Academy Logo" />
              </EGDSFigure>
            </div>
          </EGDSLayoutGridItem>
          <EGDSLayoutGridItem>
            <div>
              <EGDSHeading size={7} tag="h2">
                {data.heading}
              </EGDSHeading>
              <EGDSText>{data.description}</EGDSText>
              <EGDSSpacing margin={{ blockstart: 'one' }}>
                <EGDSLink
                  onClick={() => {
                    trackClick(buildClickStreamAnalyticsForTaapAcademySelectedEvent());
                  }}
                >
                  <a href={data.academyLink.action.resource.value}>{data.academyLink.text}</a>
                </EGDSLink>
              </EGDSSpacing>
            </div>
          </EGDSLayoutGridItem>
        </EGDSLayoutGrid>
      </EGDSCard>
    </>
  );
};
TaapAcademy.fragment = fragment;
TaapAcademy.displayName = 'TaapAcademyComponent';
