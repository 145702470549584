import React, { useState } from 'react';
import { EGDSCard } from '@egds/react-core/cards';
import { EGDSHeading } from '@egds/react-core/text';
import { gql } from '@apollo/client';
import { FragmentComponent } from 'components/component-types';
import { getHelpCenterConfig, TripInfoDetailFields } from 'common/__generated__/api/types';
import { EGDSTab, EGDSTabs, EGDSTabsBackGroundColorType, EGDSTabsType } from '@egds/react-core/tabs';
import TripsTabContent from './TripsTabContent';
interface TripInfoProps {
  data?: getHelpCenterConfig.travelerTripDetailedOverview;
}

const fragment = gql`
  fragment TripInfoDetailFields on HelpCenterTripDetailedOverview {
    header
    allTrips {
      header
      tabTitle
      tripInfos {
        backToTripButton
        header
        tripName
        image {
          description
          url
          __typename
        }
        timePeriod
        numberOfBooking
        bookingIcons {
          id
          __typename
        }
        tripBookings {
          ... on UnSupportedBooking {
            heading
            description
            clickstreamPayload {
              ...BookingActionsClickStreamAnalyticsFragment
            }
            goToTrips {
              __typename
              primary
              icon {
                id
                description
                token
                __typename
              }
              action {
                accessibility
                ... on UILinkAction {
                  __typename
                  resource {
                    value
                  }
                }
                analytics {
                  linkName
                  referrerId
                }
                __typename
              }
            }
            __typename
          }
          ... on BookingDetail {
            icon {
              id
              __typename
            }
            helpCenterIntentButton {
              name {
                primary
                __typename
              }
              skillName
              vaText
              payload
              __typename
            }
            timePeriod
            itineraryNumberText
            title
            product
            isDisable
            bookingActions {
              ... on HelpCenterLinkButton {
                name {
                  primary
                  action {
                    ... on UILinkAction {
                      resource {
                        value
                      }
                    }
                  }
                }
              }
              ... on HelpCenterIntentButtons {
                name {
                  primary
                  __typename
                }
                skillName
                vaText
                payload
                bookingActionsClickStreamAnalytics {
                  ...BookingActionsClickStreamAnalyticsFragment
                }
                __typename
              }
              ... on HelpCenterBookingButtons {
                __typename
                name {
                  primary
                  action {
                    accessibility
                    ... on UILinkAction {
                      resource {
                        value
                        __typename
                      }
                    }
                    analytics {
                      linkName
                      referrerId
                      __typename
                    }
                    __typename
                  }
                }
              }
            }
            __typename
          }
        }
        __typename
      }
    }
  }
  fragment BookingActionsClickStreamAnalyticsFragment on BookingActionsClickStreamAnalytics {
    event {
      eventName
      eventType
      eventCategory
      eventVersion
      actionLocation
      actionReason
      __typename
    }
    tripDetail {
      tripDomainId
      __typename
    }
    experience {
      pageName
      appName
      appVersion
      pageNameDetailed
      __typename
    }
    workFlowList {
      intent
      orderLineId
      productLine
      subIntent
      automationStatus
      automationStatusReason
      channel
      __typename
    }
    __typename
  }
`;

export const Trips: FragmentComponent<TripInfoProps, getHelpCenterConfig.travelerTripDetailedOverview> = (
  props: TripInfoProps
) => {
  const [tripInfoData, setTripInfoData] = React.useState<TripInfoDetailFields.tripInfos | null>(null);
  const [selected, setSelected] = useState(0);
  const selectTab = (e: number) => {
    setSelected(e);
    setTripInfoData(null);
  };
  const { data } = props;
  return (
    <EGDSCard style={{ borderRadius: '0px' }}>
      <EGDSHeading tag="h3" align="left" size={7}>
        {data?.header}
      </EGDSHeading>
      <EGDSTabs
        selectedTab={selected}
        onTabSelect={selectTab}
        tabsType={EGDSTabsType.NATURAL}
        backgroundColor={EGDSTabsBackGroundColorType.PRIMARY}
        targetURI={''}
      >
        {data?.allTrips?.map((ele: TripInfoDetailFields.allTrips) => {
          return (
            <EGDSTab name={ele?.tabTitle} targetURI={'?NaturalTargetTab=' + ele?.tabTitle} key={ele?.tabTitle}>
              <TripsTabContent data={ele} setTripInfoData={setTripInfoData} tripInfoData={tripInfoData} />
            </EGDSTab>
          );
        })}
      </EGDSTabs>
    </EGDSCard>
  );
};

Trips.fragment = fragment;
Trips.displayName = 'TripInfoDetailFields';
