import React from 'react';
import { gql } from '@apollo/client';
import { EGDSLayoutFlex, EGDSLayoutFlexItem } from '@egds/react-core/layout-flex';
import { EGDSHeading } from '@egds/react-core/text';
import { BannerFieldsFragment } from 'common/__generated__/api/types';
import { FragmentComponent } from 'components/component-types';
const fragment = gql`
  fragment BannerFields on HelpCenterWelcomeBanner {
    heading
    heroImage {
      url
      description
    }
    subHeading
  }
`;

interface BannerProps {
  data: BannerFieldsFragment;
}

export const WelcomeBanner: FragmentComponent<BannerProps, BannerFieldsFragment> = (props: BannerProps) => {
  const data = props.data;
  return (
    <div className={'welcome-div'}>
      <EGDSLayoutFlex justifyContent="center" wrap="nowrap" className={'welcome-banner no-border'} data-stid="banner">
        <EGDSLayoutFlexItem>
          <EGDSLayoutFlex justifyContent="center" alignItems="start" direction={'column'}>
            <EGDSHeading tag="h1" align="center" className={'welcome-heading'} data-stid="bannerHeading">
              {data.heading}
            </EGDSHeading>
            <EGDSHeading tag="h2" align="left" className={'welcome-subheading'} data-stid="bannerSubHeading">
              {data.subHeading}
            </EGDSHeading>
          </EGDSLayoutFlex>
        </EGDSLayoutFlexItem>
      </EGDSLayoutFlex>
    </div>
  );
};
WelcomeBanner.fragment = fragment;
WelcomeBanner.displayName = 'Banner';
