import * as React from 'react';
import { EGDSSpacing } from '@egds/react-core/spacing';
import { EGDSHeading, EGDSText } from '@egds/react-core/text';
import { EGDSCard, EGDSCardContentSection, EGDSCardLink } from '@egds/react-core/cards';
import { EGDSFigure, EGDSFigureAspectRatioType, EGDSImage } from '@egds/react-core/images';
import { EGDSIcon, EGDSIconSize } from '@egds/react-core/icons';
import { EGDSScrim, EGDSScrimType } from '@egds/react-core/scrim';
import { EGDSBadgeStandard } from '@egds/react-core/badge';
import { EGDSLayoutFlex, EGDSLayoutFlexItem } from '@egds/react-core/layout-flex';
import { ViewLarge, Viewport, ViewSmall, defaultViewSizes, useMediaQueries } from '@shared-ui/viewport-context';
import { useEgClickstream } from '@shared-ui/clickstream-analytics-context';
import { buildClickStreamAnalyticsForTripSelectedEvent } from 'common/helper';
import { useState } from 'react';
import { TripInfoDetailFields } from 'common/__generated__/api/types';
import TripDetails = TripInfoDetailFields.allTrips;

interface TripCardProps {
  data: TripDetails;
  setTripInfoData: (value) => void;
}

const TripInfoOverview: React.FC<TripCardProps> = ({ data, setTripInfoData }: TripCardProps) => {
  const trackClick = useEgClickstream();
  const viewSize = useMediaQueries({ viewSizes: defaultViewSizes });
  const [selected, setSelected] = useState(0);

  const figureContent = (trip) => {
    return (
      <>
        <EGDSImage placeholderImage={true} alt={trip?.image?.description} src={trip?.image?.url} />
        <EGDSScrim type={EGDSScrimType.OVERLAY}>
          <EGDSCardContentSection>
            <EGDSLayoutFlex direction={'column'} justifyContent={'space-between'} space={'one'}>
              <EGDSLayoutFlexItem>
                <EGDSHeading tag="h4" size={4}>
                  {trip.tripName}
                </EGDSHeading>
              </EGDSLayoutFlexItem>
              <EGDSLayoutFlexItem>
                <EGDSText>{trip.timePeriod}</EGDSText>
              </EGDSLayoutFlexItem>
              <EGDSLayoutFlexItem>
                <EGDSText>{trip.numberOfBooking}</EGDSText>
              </EGDSLayoutFlexItem>
              <EGDSLayoutFlexItem>
                {trip.tripBookings.length > 0 && (
                  <EGDSLayoutFlex space={'two'}>
                    {trip.bookingIcons.map((el, i) => (
                      <EGDSBadgeStandard key={i} theme={'standard'}>
                        <EGDSIcon name={el.id} size={EGDSIconSize.SMALL} />
                      </EGDSBadgeStandard>
                    ))}
                  </EGDSLayoutFlex>
                )}
              </EGDSLayoutFlexItem>
            </EGDSLayoutFlex>
          </EGDSCardContentSection>
        </EGDSScrim>
      </>
    );
  };

  return (
    <>
      {data.tripInfos.map((trip, index) => (
        <EGDSSpacing margin={{ blockend: 'three', inlineend: 'two' }} key={`trip-${index}`}>
          <EGDSCard selected={viewSize !== defaultViewSizes.SMALL && selected === index}>
            <Viewport>
              <ViewSmall>
                <EGDSFigure ratio={EGDSFigureAspectRatioType.R21_9}>{figureContent(trip)}</EGDSFigure>
              </ViewSmall>
              <ViewLarge>
                <EGDSFigure ratio={EGDSFigureAspectRatioType.R4_1}>{figureContent(trip)}</EGDSFigure>
              </ViewLarge>
            </Viewport>
            <EGDSCardLink>
              <button
                onClick={() => {
                  setTripInfoData(trip);
                  setSelected(index);
                  trackClick(
                    buildClickStreamAnalyticsForTripSelectedEvent(trip.tripName, trip.numberOfBooking.match(/\d+/)[0])
                  );
                }}
              >
                {trip.tripName}
              </button>
            </EGDSCardLink>
          </EGDSCard>
        </EGDSSpacing>
      ))}
    </>
  );
};

export default TripInfoOverview;
