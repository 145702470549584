import React from 'react';
import { gql } from '@apollo/client';
import { useEgClickstream } from '@shared-ui/clickstream-analytics-context';
import { FitContent, EGDSLayoutGrid, EGDSLayoutGridItem } from '@egds/react-core/layout-grid';
import { EGDSCard } from '@egds/react-core/cards';
import { EGDSHeading } from '@egds/react-core/text';
import { EGDSLayoutFlex, EGDSLayoutFlexItem } from '@egds/react-core/layout-flex';
import { EGDSSpacing } from '@egds/react-core/spacing';
import { EGDSSheetFooter } from '@egds/react-core/sheet';
import { buildClickStreamAnalyticsForSignInCtaActionEvent } from 'common/helper';
import { FragmentComponent } from 'components/component-types';
import { EGDSIllustration } from '../EgdsComponents/egds-illustration';
import { SignInCTAFieldsFragment } from 'common/__generated__/api/types';
import { InternalEGDSEGDSButton as InternalEGDSButton } from '../EgdsComponents/egds-button';
import { useCookies } from 'react-cookie';
import { ClickstreamAnalyticsEvent, ClickstreamAnalyticsExperience } from '../__fragments/ClickstreamEvent';
import { egClickStreamConstants } from 'common/constant';

const fragment = gql`
  fragment SignInCTAFields on SignInCTA {
    heading
    illustration {
      ...EGDSIllustration
    }
    signInButton {
      ...EGDSButton
    }
    dismissButton {
      ...EGDSButton
    }
    signInCTAClickstreamPayload {
      event {
        ...ClickstreamAnalyticsEvent
      }
      experience {
        ...ClickstreamAnalyticsExperience
      }
    }
    signInCTACookiePayload {
      cookieName
      cookieTTL
    }
  }
  ${EGDSIllustration.fragment}
  ${InternalEGDSButton.fragment}
  ${ClickstreamAnalyticsEvent}
  ${ClickstreamAnalyticsExperience}
`;

export const SignInCta: FragmentComponent<object, SignInCTAFieldsFragment> = ({ data }) => {
  const [cookies, setCookie] = useCookies();
  const trackClick = useEgClickstream();

  const dismissSignInCta = () => {
    const currentDate = new Date();
    const expirationDate = new Date(currentDate.getTime() + data.signInCTACookiePayload.cookieTTL);
    setCookie(data.signInCTACookiePayload.cookieName, 'yes', { path: '/helpcenter', expires: expirationDate });
    trackClick(
      buildClickStreamAnalyticsForSignInCtaActionEvent(
        egClickStreamConstants.egClickStreamEventNames.helpCenterSignInClosed,
        data.signInCTAClickstreamPayload.event,
        data.signInCTAClickstreamPayload.experience
      )
    );
  };

  const trackSignInAction = () => {
    trackClick(
      buildClickStreamAnalyticsForSignInCtaActionEvent(
        egClickStreamConstants.egClickStreamEventNames.helpCenterSignInSelected,
        data.signInCTAClickstreamPayload.event,
        data.signInCTAClickstreamPayload.experience
      )
    );
  };

  const Banner = (
    <div data-stid="signInCTA-container">
      <EGDSSpacing padding={{ blockstart: 'six' }}>
        <div>
          <EGDSLayoutGrid space="three" columns={[FitContent('280px')]} justifyContent="center">
            <EGDSLayoutGridItem>
              <EGDSSpacing padding={{ inline: 'six', block: 'six' }}>
                <EGDSCard>
                  <EGDSSheetFooter>
                    <EGDSSpacing>
                      <EGDSLayoutFlex alignItems="center" justifyContent="center" space="four" direction="column">
                        <EGDSLayoutFlexItem alignSelf="center">
                          <EGDSIllustration data={data.illustration} />
                        </EGDSLayoutFlexItem>
                        <EGDSLayoutFlexItem alignSelf="center">
                          <EGDSHeading align="center" size={6} tag="h2" data-stid="signInCTA-heading">
                            {data.heading}
                          </EGDSHeading>
                        </EGDSLayoutFlexItem>
                        <EGDSLayoutFlexItem alignSelf="center">
                          <EGDSSpacing margin={{ blockstart: 'three' }}>
                            <InternalEGDSButton
                              data={data.signInButton}
                              onClick={trackSignInAction}
                              data-stid="signInCTA-SignInButton"
                            ></InternalEGDSButton>
                          </EGDSSpacing>
                        </EGDSLayoutFlexItem>
                        <EGDSLayoutFlexItem alignSelf="center">
                          <EGDSSpacing margin={{ blockend: 'six' }}>
                            <InternalEGDSButton
                              data={data.dismissButton}
                              onClick={dismissSignInCta}
                              data-stid="signInCTA-NotRightNowButton"
                            ></InternalEGDSButton>
                          </EGDSSpacing>
                        </EGDSLayoutFlexItem>
                      </EGDSLayoutFlex>
                    </EGDSSpacing>
                  </EGDSSheetFooter>
                </EGDSCard>
              </EGDSSpacing>
            </EGDSLayoutGridItem>
          </EGDSLayoutGrid>
        </div>
      </EGDSSpacing>
    </div>
  );
  return cookies[data.signInCTACookiePayload.cookieName] !== 'yes' ? Banner : null;
};

SignInCta.fragment = fragment;
SignInCta.displayName = 'SignInCTAView';
