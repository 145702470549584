import React from 'react';
import { EGDSCard, EGDSCardContentSection } from '@egds/react-core/cards';
import TripInfoOverview from './TripInfoOverview';
import { EGDSLayoutFlex, EGDSLayoutFlexItem } from '@egds/react-core/layout-flex';
import TripInfoDetail from './TripInfoDetail';
import { ViewMedium, ViewSmall, Viewport } from '@shared-ui/viewport-context';
import { TripInfoDetailFields } from 'common/__generated__/api/types';
import TripInfos = TripInfoDetailFields.tripInfos;
import TripDetails = TripInfoDetailFields.allTrips;
interface Props {
  data?: TripDetails;
  setTripInfoData?: (value) => void;
  tripInfoData: TripInfos;
}
const TripsTabContent = (props: Props) => {
  const { tripInfoData, data, setTripInfoData } = props;
  return (
    <EGDSCard>
      <Viewport>
        <ViewSmall>
          <EGDSCardContentSection padded={false}>
            {!tripInfoData ? (
              <div className="trip-info-container-view-small">
                <TripInfoOverview data={data} setTripInfoData={setTripInfoData} />
              </div>
            ) : (
              <div className={'trip-bookings-container-view-small'}>
                <TripInfoDetail setTripInfoData={setTripInfoData} tripInfoData={tripInfoData} />
              </div>
            )}
          </EGDSCardContentSection>
        </ViewSmall>
        <ViewMedium>
          <EGDSLayoutFlex space={'two'} dataTestId="largeContent">
            <EGDSLayoutFlexItem grow={1}>
              <div>
                <div className="trip-info-container-view-medium">
                  <TripInfoOverview data={data} setTripInfoData={setTripInfoData} />
                </div>
              </div>
            </EGDSLayoutFlexItem>
            <EGDSLayoutFlexItem grow={1} style={{ maxWidth: '50%', minWidth: '50%' }}>
              <div className={'trip-bookings-container-view-medium'}>
                <TripInfoDetail tripInfoData={tripInfoData ? tripInfoData : data.tripInfos[0]} />
              </div>
            </EGDSLayoutFlexItem>
          </EGDSLayoutFlex>
        </ViewMedium>
      </Viewport>
    </EGDSCard>
  );
};

export default TripsTabContent;
